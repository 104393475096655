<template>
  <b-modal
      centered
      hide-footer
      id="eventEmailModal"
      size="xl"
      modal-class="xxl"
      title="Шаблон"
  >
    <b-row>
      <b-col cols="4" class="d-none d-md-block">
        <a-label class="text-center" :label="'Доступные макросы'" />
        <macros-list
            :categories="availableCategories"
            description-next-line
            btn-macros-class="p-2"
        />
      </b-col>
      <b-col>
        <b-form-group>
          <a-label :label="'Тема сообщения'" required />
          <b-form-input v-model="item.dv" placeholder="Тема письма" />
          <validation-error :errors="apiValidationErrors.dv" />
        </b-form-group>

        <b-form-group>
          <a-label :label="'Текст сообщения'" required />
          <template>
            <!--          <ckeditor :editor="ckEditor"  v-model="item.content" :config="editorConfig"/>-->
            <ckeditor :editor="ckEditor" @ready="onReady" v-model="item.content" :config="editorConfig" />
            <validation-error :errors="apiValidationErrors.content" />
          </template>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="my-4" />

    <div class="text-right">
      <b-button variant="primary" pill @click="save">
        Сохранить
      </b-button>
    </div>

  </b-modal>
</template>

<script>
import TemplateResource from "../../../../resources/template";
import MacrosList from "../Macros/MacrosList";
import DictResource from "../../../../resources/dict";

export default {
  name: "EventEmailModal",
  components: {
    MacrosList,
  },
  data() {
    return {
      item: {},
      templates: [],
    }
  },
  props: {
    event: {
      type: Object,
      default: () => { return {} }
    },
  },
  watch: {
    event: {
      handler() {
        this.item = this.event;
        if (!this.item.content && this.item.template_id) {
          const eventTemplate = this.templates.filter(
              (elm) => elm.id === this.item.template_id,
          )[0];
          this.item.content = eventTemplate.content;
        }
      },
    }
  },
  computed: {
    availableCategories() {
      const categories = this.$store.getters.getDict('dict_macros_categories');
      return categories.map((item) => item.id);
    },
  },
  async mounted() {
    const typeNotification = 5;
    this.templates = await TemplateResource.fetch({
      dict_type_id: typeNotification
    });
  },
  methods: {
    async save() {
      DictResource.setUrl('dict_events');
      await this.validateAndSave(DictResource);
    },
  },
}
</script>

<style scoped>

</style>