<template>
  <b-modal
      id="entryThesisModal"
      ref="entryThesisModal"
      size="xl"
      title= "Тезисы"
      ok-title="Сохранить"
      @ok="save"
      :ok-disabled="!isLengthValid"
      cancel-title="Отмена"
      centered
      :hide-footer="isEditClosed && !isManager"
      button-size="xl"
  >
    <div class="mb--2">
      Услуга: <b>{{entry.service.title}}</b> <br>
      <small>Пользователь: <b>{{entry.user.fio}}</b></small>
      <a-label class="mt-3" :label="'Тезисы:'" required />
      <b-form-textarea
          :disabled="isEditClosed && !isManager"
          placeholder="Введите тезисы..."
          rows="20"
          v-model="item.text"
      />
      <validation-error :errors="apiValidationErrors.text" />
      <div class="mt-3" v-if="!entry.thesis || isManager">
        Вы ввели: {{ item.text.length }} <br>
        Размер принимаемых тезисов: {{ minTextLength }}-{{ maxTextLength }} символов <br>
        <div class="text-danger" v-if="!item.text || item.text.length < minTextLength">
          Допишите ваши тезисы, сейчас они слишком короткие (минимум 1500 символов)
        </div>
        <div class="text-danger" v-if="item.text.length > maxTextLength">
          Слишком много символов! (максимум 10000 символов)
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import EntryThesisResource from "../../../resources/entry-thesis";

export default {
  name: "EntryThesisModal",
  data() {
    return {
      item: {},
      newItem: {
        text : "",
        entry_id : "",
      },
      minTextLength: 1500,
      maxTextLength: 10000,
    };
  },
  props: {
    entry: {
      type: Object,
      default() {
        return {
          text : "",
          entry_id : "",
        }
      }
    }
  },
  watch: {
    entry: {
      handler() {
        this.fetchData();
      },
    },
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    isEditClosed() {
      const hasThesis = !!this.entry.thesis;
      return this.entry.is_paid && hasThesis;
    },
    isLengthValid() {
      if (this.item.text) {
        const textLength = this.item.text.length;
        return textLength > this.minTextLength && textLength < this.maxTextLength;
      }
      return false;
    }
  },
  methods: {
    fetchData() {
      const newItem = {...this.newItem};
      this.item = this.entry.thesis ?? newItem;
    },
    async save() {
      this.item.entry_id = this.entry.id;
      await this.validateAndSave(EntryThesisResource, this.item, false);
      this.$emit('on-update');
    },
  },
}
</script>

<style scoped>
:disabled {
  background: var(--white) !important;
  border: none !important;
}
</style>