<template>
  <b-modal
      size="lg"
      :title="item.fio ? item.fio : 'Добавление ведущего'"
      title-class="text-lg p-2 text-center"
      id="leadingFormsModal"
      centered
      cancel-title="Закрыть"
      ok-title="Сохранить"
      @ok="saveItem"
      button-size="xl"
  >
    <b-form-group class="mx-4 mb-3">
      <a-label label="ФИО" required />
      <b-form-input v-model="item.fio" placeholder="ФИО" />
      <validation-error :errors="apiValidationErrors.fio" />
    </b-form-group>

    <b-form-group class="mx-4 mb-3">
      <a-label label="Описание" />
      <b-form-textarea rows="6" v-model="item.description" placeholder="Описание" />
    </b-form-group>

    <b-form-group class="mx-4 mb-3">
      <a-label label="Ссылка" />
      <b-form-input v-model="item.url" placeholder="Ссылка" />
    </b-form-group>
  </b-modal>
</template>

<script>
import LeadingResource from "../../../../resources/leading";

export default {
  name: "LeadingFormsModal",
  props: {
    item: {
      type: Object,
    },
  },
  methods: {
    async saveItem() {
      await this.validateAndSave(LeadingResource, this.item);
      this.$emit('on-update');
    },
  },
}
</script>

<style scoped>

</style>