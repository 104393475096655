<template>
  <div class="col-xl-8 mb-5 mb-xl-0">
    <card :type="variant" header-classes="bg-transparent">
      <div slot="header" class="row align-items-center">
        <div class="col">
          <h6 class="text-light text-uppercase ls-1 mb-1">Статистика</h6>
          <h5 class="h3 text-white mb-0">Доход</h5>
        </div>
      </div>
      <line-chart
          :height="350"
          ref="bigChart"
          :chart-data="bigLineChart.chartData"
          :extra-options="bigLineChart.extraOptions"
      >
      </line-chart>
    </card>
  </div>
</template>

<script>
import * as chartConfigs from "@/components/Charts/config";
import LineChart from "@/components/Charts/LineChart";

export default {
  name: "StatisticsFinance",
  components: {
    LineChart,
  },
  props: {
    variant: {
      type: String,
      default: 'dark',
    },
    bgColor: {
      type: String,
      default: 'transparent',
    },
  },
  data() {
    return {
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
        chartData: {
          datasets: [],
          labels: [],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
    }
  },
  mounted() {
    this.initBigChart(0);
  },
  computed: {
    paymentStatistics() {
      return this.$store.getters.getStatisticsByName('payment');
    }
  },
  methods: {
    initBigChart(index) {
      const months = Object.keys(this.paymentStatistics);
      const numbers = Object.values(this.paymentStatistics);
      const numbersFormatted = numbers.map(elm => elm / 1000);

      let chartData = {
        datasets: [
          {
            label: "Доход, тыс.",
            backgroundColor: this.bgColor,
            data: numbersFormatted,
          },
        ],
        labels: months,
      };
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    },
  },
}
</script>

<style scoped>

</style>