<template>
  <div>
    <base-header type="default" class="pb-0 pb-md-4 mt--5 mt-md-0" />
    <div class="container-fluid mt-5 col-xl-6">
      <card body-classes="px-0 pb-1" footer-classes="pb-2">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">Регионы</h3>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <base-table
            class="table align-items-center table-flush"
            tbody-classes="list"
            :data="items"
            :thead-classes="'thead-light'"
          >
            <template slot="columns">
              <th>#</th>
              <th>Название</th>
<!--              <th></th>-->
            </template>

            <template slot-scope="{ row }">
              <td>{{ row.id }}</td>
              <td>{{ row.fdv }}</td>
<!--              <td>-->
<!--                <b-btn tag="a" variant="outline-primary" @click="editItem(row)" class="mr-2 pointer" disabled>-->
<!--                  <b-icon-pencil-fill scale="1.3" />-->
<!--                </b-btn>-->
<!--                <b-btn tag="a" variant="outline-danger" @click="remove(row)" disabled>-->
<!--                  <i class="fas fa-trash" />-->
<!--                </b-btn>-->
<!--              </td>-->
            </template>
          </base-table>
        </div>

        <div class="card-footer d-flex justify-content-end">
<!--          <base-pagination></base-pagination>-->
<!--          <base-pagination total="1"></base-pagination>-->
        </div>
      </card>
    </div>
<!--    <item-edit-modal :item="selectedItem" @on-update="fetchData" />-->
  </div>
</template>
<script>
// import ItemEditModal from "./Edit/ItemEditModal";

export default {
  name: "RegionList",
  // components: {ItemEditModal},
  data() {
    return {
      items: [],
      selectedItem: null,
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.items = await this.$store.getters.getDict('dict_regions');
    },
    async remove(item) {
      await this.fetchData();
    },
    editItem(item) {
      this.selectedItem = item;
      // this.$bvModal.show('userEditModal');
    }
  },
};
</script>
<style>

</style>
