<template>
  <div>
    <b-btn variant="link" href="#" :class="btnClearFiltersClass" class="float-right p-0 mr-4" @click="clearFilters">
      <span class="text-dark font-weight-400 dotted mt--1"> <b-icon-x /> Сбросить фильтры </span>
    </b-btn>
    <div class="table-responsive">
      <base-table
          :loading="loading"
          class="table align-items-center table-flush"
          tbody-classes="list dashed"
          :data="entries"
          :thead-classes="'thead-light'"
          :skeleton-columns="[0, 1, 2, 3, 4, 5, 6, 7]"
      >

        <template slot="columns">
          <th class="text-center col-1">№</th>
          <th class="col-2" v-if="!hideFio">Клиент</th>
          <th class="col-2" v-if="!hideService"></th>
          <th v-if="!hideService">Мероприятие</th>
          <th v-if="showSection">Секция</th>
          <th class="text-center">Статус</th>
          <th v-if="isManager">Дата создания</th>
          <th v-else class="text-center">Дата</th>
          <th class="text-wrap text-center">Тезисы / Публикация</th>
          <th></th>
        </template>

        <template slot="filters" v-if="!hideFilters">
          <th>
            <b-input v-model="filters.number" placeholder="№" @change="changePageWithFilters" />
          </th>
          <th v-if="!hideFio">
            <b-input v-model="filters.fio" placeholder="ФИО" @input="changePageWithFilters" />
          </th>
          <th v-if="!hideService">
            <l-select
                :options="typesExtended"
                placeholder-text="..."
                v-model="filters.type"
                @input="changePageWithFilters"
                class="overflow-hidden"
                add-default-option
            />
          </th>
          <th v-if="!hideService">
            <b-input v-model="filters.service" placeholder="Мероприятие" @input="changePageWithFilters" />
          </th>
          <th v-if="showSection"></th>
          <th class="text-center">
            <l-select
                :options="statuses"
                placeholder-text="..."
                v-model="filters.status"
                @input="changePageWithFilters"
                class="overflow-hidden"
                add-default-option
            />
          </th>
          <th v-if="!hideCreatedAt && isManager">
            <b-input
                v-model="filters.date"
                v-inputmask="'99.99.9999'"
                placeholder="Дата"
                @change="changePageWithFilters"
            />
          </th>
          <th></th>
          <th>
            <b-form-radio-group
                v-if="isManager"
                id="radio-status-filter"
                v-model="filters.deleted"
                :options="lifeStatuses"
                buttons
                @input="changePageWithFilters"
            />
          </th>
        </template>

        <template slot-scope="{ row }" v-if="!loading">
          <td class="text-center">
            {{ row.number }}
          </td>
          <td class="col-1 text-wrap" v-if="!hideFio">
            <template v-if="row.user">
              {{ row.user.fio }}
            </template>
          </td>

          <!--     ТИП УСЛУГИ     -->
          <td v-if="!hideService" class="d-flex my-3 border-0">
            <h2><i :class="`${row.service.type.icon} text-${colorServiceType(row)} pt-2`" /></h2>
<!--            <span class="text-wrap ml-3 small" :class="{'pt-2': isServiceTypePadding(row)}">-->
            <span class="text-wrap mx-3 muted pt-2">
              {{ getServiceType(row) }}
              <br>
              <span class="text-xs bg-purple px-1 text-white" v-if="row.is_service_package"> 
                Пакет услуг 
              </span>
            </span>
          </td>

          <!--     УСЛУГА     -->
          <td class="text-wrap my-3 py-3" v-if="!hideService" >
            {{ row.service.title }} <br>
            <!--     заголовок публикации (если публикации)       -->
            <template v-if="row.publication">
              <i>«{{ row.publication.title }}»</i><br>
              <a :href="row.publication.url" target="_blank" v-if="row.publication.url">
                <b-icon-link45deg />
                Просмотреть на сайте
              </a>
            </template>
            <!--      даты проведения мероприятия (для оператора)      -->
            <div
                class="mt-1 bg-primary px-1 text-white text-nowrap w-fit-content d-flex"
                v-if="row.service.date_type === dateTypeCurrent"
            >
              <span v-if="isManager">
                {{ row.service.interval_formatted }}
              </span>
            </div>
          </td>

          <td class="text-wrap" v-if="showSection">
            <b>{{ row.section ? row.section.title : '' }}</b>
          </td>
          <td class="text-center">

            <!--        Оплата/статус        -->
            <template v-if="canBePaid(row)">
              <l-select
                  v-if="isManager"
                  :options="paymentTypes"
                  @input="save(row)"
                  class="l-select-pay overflow-clip ml--2 mr--2"
                  v-model="row.pay_type"
                  placeholder-text="..."
                  add-default-option
              />
              <b-btn
                  v-if="!row.is_service_package"
                  v-b-tooltip.left="'Оплатить'"
                  class="pl-2 pr-2 p-1 bg-gradient-danger border-0 text-white"
                  :class="{ 'mt-2': isManager }"
                  @click="pay(row, true)"
              >
                {{row.price}} ₽
              </b-btn>
              <template v-else>
                <h4><b-badge variant="default">
                  Ожидается ответ <br> от ЮКассы
                </b-badge></h4>
              </template>
            </template>
            <template v-else-if="row.publication">
              <h4><b-badge :variant="row.publication.statusColor">
                {{ row.publication.statusLabel }}
              </b-badge></h4>
              <small>{{row.price}} ₽</small>
            </template>
            <template v-else>
              <h4><b-badge :variant="row.statusColor">
                {{ row.statusLabel }}
              </b-badge></h4>
              <small>{{row.price}} ₽</small>
            </template>
          </td>

          <!--    Дата проведения      -->
          <td class="text-wrap text-center">
            <template v-if="isManager">
              {{ row.created_at_formatted }}
            </template>
            <template v-else>
              <div
                  :class="row.status === statusDone ? '' : 'text-white bg-primary'"
                  class="ml-3 p-1 rounded-lg d-flex"
                  v-if="row.service.date_type === dateTypeCurrent"
              >
                <i :class="row.service.date_type_icon" class="mt-2 px-2"/>
                <div class="text-calendar text-xs ml-1">
                  {{ row.service.interval_formatted }}
                </div>
              </div>
              <h4 v-else><b-badge :variant="row.status === statusDone ? 'light' : 'primary'">
                без даты
              </b-badge></h4>
            </template>
          </td>

          <!--     Добавление тезисов/просмотр публикации     -->
          <td class="text-center">
            <template v-if="row.is_thesis_needed">
              <!--     Добавление тезисов       -->
              <b-btn
                  variant="warning"
                  v-if="!row.service.is_publication && (!isManager && !row.thesis)"
                  @click="changeThesis(row)"
              >
                + <i class="fa fa-tag" />
              </b-btn>
              <!--     Просмотр добавленных тезисов       -->
              <b-btn
                  v-if="!row.service.is_publication && !!row.thesis"
                  @click="changeThesis(row)"
              >
                <b-icon-eye-fill font-scale="1.3" />
              </b-btn>
            </template>
            <template v-if="row.service.is_publication">
              <h4>
                <b-badge
                  v-if="row.publication"
                  :variant="row.publication.statusColor"
                >
                  {{ row.publication.statusLabel }}
                </b-badge>
              </h4>
              <b-btn
                  variant="default"
                  @click="editPublication(row)"
              >
                <b-icon-card-text font-scale="1.3" />
              </b-btn>
            </template>
          </td>

          <!--    ПЕЧАТЬ ДОКУМЕНТОВ     -->
          <td class="text-center">
            <template v-if="!row.deleted_at">
              <template v-if="(row.status !== statusDone && !isManager) || isManager">
                <a-actions :item="row" @edit="editItem" @remove="remove" :disable-remove="row.status >= statusPaid && !isManager">
                  <b-dropdown-item v-if="row.publication && row.publication.url" :href="row.publication.url" target="_blank">
                    <b-row>
                      <b-col cols="1"><i class="ni ni-curved-next" /></b-col>
                      <b-col align="left"><span>Ссылка</span></b-col>
                    </b-row>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="row.publication && row.publication.url_contents" :href="row.publication.url_contents" target="_blank">
                    <b-row>
                      <b-col cols="1"><i class="ni ni-curved-next" /></b-col>
                      <b-col align="left"><span>Оглавление</span></b-col>
                    </b-row>
                  </b-dropdown-item>
                  <b-dropdown-item @click="editPublication(row)" v-if="row.service.is_publication">
                    <b-row>
                      <b-col cols="1"><i class="ni ni-single-copy-04" /></b-col>
                      <b-col align="left"><span>Публикация</span></b-col>
                    </b-row>
                  </b-dropdown-item>
                </a-actions>
              </template>
              <template v-if="((!row.publication && row.status === statusDone) || isPublicationDone(row)) || isManager">
                <entry-print v-b-tooltip.right="'Печать документов'" :item="row" />
              </template>
            </template>
            <template v-else>
              <button class="btn btn-link text-dark" @click="restore(row)">
                <b-icon-arrow-counterclockwise font-scale="1.5"/>
              </button>
            </template>
          </td>
        </template>

      </base-table>
    </div>

    <router-link to="/user/service" v-if="!entriesPagination.total">
      <b-alert variant="warning" show class="m-4">
        У Вас нет ни одной заявки! Подайте заявку на услугу из раздела "Услуги"
      </b-alert>
    </router-link>

      <entry-edit-modal :entry="selected" />
      <publication-entry-modal :entry="selected" @on-update="$emit('on-update')" />
      <entry-thesis-modal :entry="selected" @on-update="$emit('on-update')"/>

    <div
        v-if="entriesPagination.last_page > 1"
        class="card-footer d-flex justify-content-end"
    >
      <base-pagination
          :page-count="entriesPagination.last_page"
          :value="entriesPagination.current_page"
          @step="changePage"
      />
    </div>

  </div>
</template>

<script>
import paymentMixin from "../../../mixins/payment-mixin";
import EntryResource from "../../../resources/entry";
import EntryThesisModal from "./EntryThesisModal";
import EntryEditModal from "./EntryEditModal";
import EntryPrint from "./EntryPrint";
import PublicationEntryModal from "./PublicationEntryModal.vue";

export default {
  name: "EntryList",
  components: {
    EntryThesisModal,
    EntryEditModal,
    PublicationEntryModal,
    EntryPrint
  },
  mixins: [
    paymentMixin
  ],
  data() {
    return {
      filters: {
        id: null,
        fio: null,
        service: null,
        status: null,
        date: null,
        deleted: null,
      },
      selected: {
        thesis: {},
        service: {
          sections: [],
        },
        publication: {
          status: 1
        },
        user: {},
      },
      statuses: [
        { value: 1, label: 'Не оплачена' },
        { value: 2, label: 'Оплачена' },
        { value: 3, label: 'Завершена' },
      ],
      lifeStatuses: [
        { text: 'Все', value: null },
        { text: 'Удалённые', value: 1 },
      ],
      statusNotPaid: 1,
      statusPaid: 2,
      statusDone: 3,
      dateTypeCurrent: 1,
    }
  },
  props: {
    loading: {
      type: Boolean,
    },
    entries: {
      type: Array,
    },
    entriesPagination: {
      type: Object,
    },
    hideFio: {
      type: Boolean,
      default() {
        return false;
      }
    },
    hideService: {
      type: Boolean,
      default() {
        return false;
      }
    },
    hideCreatedAt: {
      type: Boolean,
      default() {
        return false;
      }
    },
    hideFilters: {
      type: Boolean,
      default() {
        return false;
      }
    },
    showSection: {
      type: Boolean,
      default() {
        return false;
      }
    },
    btnClearFiltersClass: {
      type: String,
      default: 'mt--5'
    },
  },
  methods: {
    /**
     * Цвет типа услуги, если заявка не удалена
     * @param row
     * @returns {*}
     */
    colorServiceType(row) {
      if (row.deleted_at) return 'muted';
      return row.service.type.color;
    },
    /**
     * Может ли пользователь оплатить заявку
     * @param entry
     * @returns {*|boolean|(function(): boolean)}
     */
    canBePaid(entry) {
      return entry.status === this.statusNotPaid;
    },
    /**
     * Может ли пользователь оплатить заявку
     * (до 2023 года, с учётом статуса публикаций)
     * @param entry
     * @returns {*|boolean|(function(): boolean)}
     * @deprecated
     */
    canBePaidOld(entry) {
      const statusApproved = 2;
      const isPublication = entry.publication;
      const entryNotPaid = entry.status === this.statusNotPaid;
      const publicationAccepted = isPublication
          && entry.publication.status === statusApproved;

      const canPayEntry = !isPublication && entryNotPaid;
      const canPayPublication = entryNotPaid && publicationAccepted;

      return canPayEntry || canPayPublication;
    },
    /**
     * Завершена ли публикация (публикация считается
     * завершённой, если она была оплачена и одобрена)
     * @param entry
     * @returns {(function(): boolean}
     */
    isPublicationDone(entry) {
      const statusApproved = 2;
      const statusPublished = 4;
      const isPaid = entry.status >= this.statusPaid;
      if (!entry.publication) return false;
      const isAccepted = entry.publication.status === statusApproved;
      const isPublished = entry.publication.status === statusPublished;
      return isPaid && (isAccepted || isPublished);
    },
    async remove(entry) {
      await this.deleteItem(EntryResource, entry.id);
      this.$emit('on-update', this.entriesPagination.current_page, this.filters);
      await this.$store.dispatch('me');
    },
    editItem(entry) {
      this.selected = entry;
      // if (entry.service.is_publication) {
      //   this.$bvModal.show('publicationEditModal');
      // } else {
        this.$bvModal.show('entryEditModal');
      // }
    },
    editPublication(entry) {
      this.selected = entry;
      this.$bvModal.show('publicationEditModal');
    },
    changeThesis(entry) {
      this.selected = entry;
      this.$bvModal.show('entryThesisModal');
    },
    getServiceType(entry) {
      const type = entry.service.type.dv;
      const subtype = entry.service.subtype ? ` - ${entry.service.subtype.fdv}` : ''
      return type + subtype;
    },
    /**
     * Нужен ли отступ сверху для типа услуги
     * @param entry
     * @returns {boolean}
     */
    isServiceTypePadding(entry) {
      return this.getServiceType(entry).split(' ').length <= 3
    },
    /**
     * Обновляет заявку (нужно для интерактивного
     * редактирования типа оплаты)
     * @param entry
     */
    async save(entry) {
      await EntryResource.save(entry);
      this.$notify({
        type: 'success',
        message: `Тип оплаты успешно изменён!`,
      });
    },
    async restore(entry) {
      entry.deleted_at = null;
      await this.save(entry);
      this.$emit('on-update', this.entriesPagination.current_page, this.filters);
    },
  },
}
</script>

<style>
.dashed tr {
  border-top: .2rem dashed rgba(0, 0, 0, 0.15) !important;
  border-bottom: .2rem dashed rgba(0, 0, 0, 0.15) !important;
}

.l-select-pay * {
  font-size: .8rem !important;
}

.text-calendar {
  border-left: 2px solid white;
  padding-left: .5rem;
}

#radio-status-filter {
  margin-top: -.2rem;
}
#radio-status-filter .btn {
  box-shadow: none;
  border-radius: .8rem;
  padding: .5rem;
}
</style>
