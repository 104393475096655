<template>
  <div>
    <base-header class="pt-0 pt-md-6 pt-lg-3"/>
    <span class="mask bg-dark" />

    <div class="container-fluid mt-0 mt-lg-5 text-center">
      <div class="row">
        <b-card
            @click="addItem"
            class="pl-0 mt-2 col-12 col-lg-4 opacity-4 bg-transparent cursor-pointer"
        >
          <b-card-header
              header-bg-variant="white"
              header-text-variant="dark"
          >
            Добавить пакет услуг
          </b-card-header>
          <b-card-body class="bg-white pb-5">
            <b-icon-plus-lg />
          </b-card-body>
        </b-card>

        <b-card
            v-b-tooltip.right="'Пакет, закреплённый для пользователей без профиля.'"
            class="pl-0 mt--3 mt-lg-2 col-12 col-lg-4 opacity-4 bg-transparent"
        >
          <b-card-header
              :header-bg-variant="pinnedServicePackage ? pinnedServicePackageColor : `white`"
              :header-text-variant="pinnedServicePackage ? `white` : `dark`"
          >
            {{ pinnedServicePackage ? pinnedServicePackage.title : 'Нет закреплённого' }}
          </b-card-header>
          <b-card-body class="bg-white pb-5">
            <b-icon-pin-fill />
          </b-card-body>
        </b-card>
      </div>

      <service-packages @on-update="fetchData" :reload="reload" @on-edit="editItem" />
    </div>

    <service-package-modal @on-update="reloadItems" :selected="selected" />

  </div>
</template>

<script>
import ServicePackages from "../../shared/ServicePackages/ServicePackages";
import ServicePackageModal from "./ServicePackageModal";
import ServicePackageResource from "../../../resources/service-package";

export default {
  name: "ServicePackageManagement",
  components: {
    ServicePackages,
    ServicePackageModal,
  },
  data() {
    return {
      pinnedServicePackage: {},
      selected: {},
      reload: 0,
    };
  },
  computed: {
    pinnedServicePackageColor() {
      return this.pinnedServicePackage.color ?? 'purple';
    }
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      const pinnedServicePackages = await ServicePackageResource.fetch({
        is_pinned: true,
        dict_profile_id: null,
      });
      this.pinnedServicePackage = pinnedServicePackages[0];
    },
    reloadItems() {
      this.reload++;
    },
    addItem() {
      this.selected = {
        services: [],
      };
      this.$bvModal.show('servicePackageModal');
    },
    editItem(item) {
      this.selected = item;
      this.$bvModal.show('servicePackageModal');
    }
  }
}
</script>

<style>
.cursor-pointer:hover {
  opacity: 100 !important;
}

.cursor-pointer {
  cursor: pointer;
}
</style>