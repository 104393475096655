<template>
  <div>

    <!--  ФИЛЬТРЫ   -->
    <b-row class="ml-2 mt-4">
      <l-select
          placeholder-text="Профиль"
          dict="dict_profiles"
          v-model="filters.profile"
          class="col-11 col-xl-3 mb-3 mb-xl-0"
          @input="fetchDataWithFilters"
          add-default-option
          default-option-label="Профиль (все)"
      />
      <l-select
          placeholder-text="Ведущий"
          :options="leadings"
          v-model="filters.leading"
          class="col-11 col-xl-3 mb-3 mb-xl-0"
          @input="fetchDataWithFilters"
          add-default-option
          default-option-label="Ведущий (все)"
      />
      <b-form-group class="ml-3 mb-0">
        <b-form-radio-group
            id="radio-time-filter"
            v-model="filters.past"
            :options="timeFilters"
            buttons
            @input="fetchDataWithFilters"
        />
      </b-form-group>
    </b-row>

    <!--  УСЛУГИ   -->
    <template v-if="!isLoading && issetServices">
      <div class="card-body">
        <div class="row icon-examples">
            <div
                :class="service.points > 0 ? 'col-lg-6' : 'col-lg-4'"
                v-for="(service) in items"
                :key="service.id"
            >
              <button
                  @click="enroll(service)"
                  type="button"
                  :class="{ 'text-muted': service.is_done }"
                  class="btn-icon-clipboard"
                  data-clipboard-text="air-baloon"
              >
                <b-row>

                  <i
                      :class="iconDateType(service)"
                      class="d-none d-md-block ml-2"
                  />
                  <b-col :cols="service.points > 0 ? 6 : 8">
                    <span class="text-wrap mb-2 font-weight-600">
<!--                      <b-badge-->
<!--                          v-if="isServiceDoneByUser(service)"-->
<!--                          class="text-xs text-success p-0 m-0 bg-transparent"-->
<!--                      >-->
<!--                        (Выполнена)-->
<!--                      </b-badge>-->
                      {{ service.title }}
                    </span>
                    <div v-if="service.date_type === dateTypeCurrent">
                    <span class="text-center mt-1">
                      {{ service.interval_formatted }}
                    </span><br>
                    </div>
                    <!-- Раньше баллы и цена были здесь -->
                  </b-col>
                  <b-col :cols="service.points > 0 ? 5 : 1">
                    <b-badge variant="primary" v-if="service.points">
                      {{ service.points }} баллов
                    </b-badge>
                    <b-badge variant="warning">
                      {{ service.price }} ₽
                    </b-badge>
                  </b-col>
                  <!--                <b-col cols="12" class="text-right">-->
                  <!--                  <b-btn variant="success" @click="enroll(service)">-->
                  <!--                    Записаться-->
                  <!--                  </b-btn>-->
                  <!--                </b-col>-->
                </b-row>
              </button>
            </div>
        </div>
      </div>

      <div class="d-flex justify-content-end mr-5" v-if="itemsPagination.last_page > 1">
        <base-pagination
            :page-count="itemsPagination.last_page"
            :value="itemsPagination.current_page"
            @step="fetchData"
        />
      </div>


    </template>

    <template v-if="isLoading">
      <service-loader />
    </template>

    <template v-if="!issetServices">
      <div class="ml-4 my-2"> Ничего не найдено </div>
    </template>

    <service-entry :service="selectedService" @on-update="fetchData" />

  </div>
</template>
<script>
import ServiceResource from "../../../resources/service";
import ServiceEntry from "./ServiceEntry";
import ServiceLoader from "./ServiceLoader";
import LeadingResource from "../../../resources/leading";

export default {
  name: "ServiceList",
  components: {
    ServiceEntry,
    ServiceLoader
  },
  data() {
    return {
      isLoading: true,
      items: [],
      itemsPagination: null,
      selectedService: {
        sections: [],
      },
      showServices: false,
      filters: {
        profile: null,
        leading: null,
        past: null,
      },
      leadings: [],
      timeFilters: [
        { text: 'Все', value: null },
        { text: 'Прошедшие', value: 1 },
        { text: 'Грядущие', value: 0 },
      ],
      dateTypeCurrent: 1,
    };
  },
  computed: {
    issetServices() {
      return this.items.length > 0;
    }
  },
  props: {
    typeId: {},
    subtypeId: {},
    preloadedService: {},
  },
  async mounted() {
    await this.fetchData();
  },
  watch: {
    typeId: {
      handler() {
        this.fetchData();
      }
    },
    subtypeId: {
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    async fetchData(page, perPage) {
      if (this.$route.params.id !== undefined && this.preloadedService.is_visible)
        this.enroll(this.preloadedService);
      this.filters.dict_type_id = this.typeId;
      this.filters.dict_subtype_id = this.subtypeId;
      this.filters.is_visible = 1;
      this.filters.userId = this.$store.getters.user.id;

      this.fetch(ServiceResource, this.filters, page, perPage);

      const leadings = await LeadingResource.fetch();
      this.leadings = leadings.map((elm) => ({
        ...elm,
        value: elm.id,
        label: elm.fio,
      }))

      this.isLoading = false;
    },
    /**
     * Запись на услугу
     * @param service
     */
    enroll(service) {
      if (this.$route.params.id === undefined)
        this.$router.push(`/user/service/${service.id}`);
      this.selectedService = service;
      this.$bvModal.show(`serviceEntryModal`);
    },
    /**
     * Иконка услуги
     */
    iconDateType(service) {
      // 'ni ni-check-bold text-muted'
      return !service.is_done ?
          service.date_type_icon :
          `${service.date_type_icon} text-muted`
    },
  },
};
</script>
<style>
#radio-time-filter {
  margin-top: -.2rem;
}
#radio-time-filter .btn {
  box-shadow: none;
  border-radius: 1rem;
}
</style>
