<template>
  <div class="col-xl-4">
    <card :type="variant" header-classes="bg-transparent">
      <div slot="header" class="row align-items-center" :class="classHeader">
        <div class="col">
          <h6 class="text-uppercase text-muted ls-1 mb-1">Статистика</h6>
          <h5 class="h3 mb-0">Заявки</h5>
        </div>
      </div>

      <bar-chart
          :height="350"
          ref="barChart"
          :chart-data="redBarChart.chartData"
          v-if="redBarChart.chartData.labels.length > 0"
      >
      </bar-chart>
    </card>
  </div>
</template>

<script>
import BarChart from "@/components/Charts/BarChart";

export default {
  name: "StatisticsEntry",
  components: {
    BarChart,
  },
  props: {
    variant: {
      type: String,
      default: 'white',
    },
    bgColor: {
      type: String,
      default: 'transparent',
    },
    classHeader: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      redBarChart: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: "Заявок",
              data: [],
              maxBarThickness: 10,
            },
          ],
        },
      },
    };
  },
  mounted() {
    const months = Object.keys(this.entryStatistics);
    const numbers = Object.values(this.entryStatistics);
    this.redBarChart.chartData.labels = months;
    this.redBarChart.chartData.datasets[0].data = numbers;
  },
  computed: {
    entryStatistics() {
      return this.$store.getters.getStatisticsByName('entry');
    }
  }
}
</script>

<style scoped>

</style>