import Resource from "../resource";
import axios from "axios";

export default class PublicationResource extends Resource {

    /**
     * Публикация всех одобренных интернет-публикаций
     * @returns {Promise<any>}
     */
    static async publishAccepted() {
        const url = `${this.backendUrl}/${this.url}/publish-accepted`;
        const { data } = await axios.post(url, {});
        return data;
    }

    /**
     * Опубликовать
     * @param params
     * @param publicationId
     * @returns {Promise<any>}
     */
    static async acceptPublication(params, publicationId) {
        const url = `${this.backendUrl}/${this.url}/${publicationId}/accept`;
        const { data } = await axios.post(url, params);
        return data;
    }

    /**
     * Отправить на корректировку
     * @param params
     * @param publicationId
     * @returns {Promise<*>}
     */
    static async declinePublication(params, publicationId) {
        const url = `${this.backendUrl}/${this.url}/${publicationId}/decline`;
        const { data } = axios.post(url, params);
        return data;
    }

    /**
     * Отклонить
     * @param params
     * @param publicationId
     * @returns {Promise<*>}
     */
    static async reject(params, publicationId) {
        const url = `${this.backendUrl}/${this.url}/${publicationId}/reject`;
        const { data } = axios.post(url, params);
        return data;
    }

    /**
     * Отклонить без отправки письма пользователю
     * @param params
     * @param publicationId
     * @returns {Promise<*>}
     */
    static async rejectSilently(params, publicationId) {
        const url = `${this.backendUrl}/${this.url}/${publicationId}/reject`;
        const { data } = axios.post(url, { is_silent: true });
        return data;
    }

    /**
     * Взять в журнал
     * @param params
     * @param publicationId
     * @returns {Promise<*>}
     */
    static async sendToJournal(params, publicationId) {
        const url = `${this.backendUrl}/${this.url}/${publicationId}/send-to-journal`;
        const { data } = axios.post(url, params);
        return data;
    }

    /**
     * Проверяет уникальность через api плагиатора
     * @param publicationId
     * @returns {Promise<*>}
     */
    static async checkUniqueness(publicationId) {
        const url = `${this.backendUrl}/${this.url}/${publicationId}/check-uniqueness`;
        const { data } = await axios.post(url, {});
        return data;
    }

    /**
     * Добавление автора к публикации
     * @param params
     * @param publicationId
     * @returns {Promise<any>}
     */
    static async addAuthor(publicationId, authorId) {
        const url = `${this.backendUrl}/${this.url}/${publicationId}/add-author/${authorId}`;
        const { data } = await axios.post(url, {});
        return data;
    }

    /**
     * Добавление url к публикациям
     * @param params
     * @returns {Promise<any>}
     */
    static async addToRelease(params) {
        const url = `${this.backendUrl}/${this.url}/add-to-release`;
        const { data } = await axios.post(url, params);
        return data;
    }
}

PublicationResource.url = 'publication';