<template>
  <div>
    <base-header type="default" class="pb-0 pb-md-4 mt--5 mt-md-0" />
    <div class="container-fluid mt-5 col-xl-9">
        <div class="card-header border-0 bg-transparent">
          <div class="row align-items-center">
            <div class="col">
              <b-btn variant="primary" pill class="float-right" @click="editItem({})">
                + Добавить
              </b-btn>
              <h3 class="mb-0 text-white">Выпуски журнала</h3>
            </div>
          </div>
        </div>

        <template v-if="!isLoading">
          <div class="card table-responsive table-striped">
            <base-table
                class="table align-items-center table-flush"
                tbody-classes="list"
                :data="items"
                :thead-classes="'thead-light'"
            >
              <template slot="columns">
                <th>Категория</th>
                <th>Название</th>
                <th></th>
              </template>

              <template slot-scope="{ row }">
                <td class="text-wrap" :class="{'text-muted': row.is_hidden}">
                  <i class="ni ni-single-copy-04 mr-2" :class="{'text-primary': !row.is_hidden}" />
                  {{ $store.getters.getDictItemById('dict_service_subtypes', row.dict_service_subtype_id).fdv }}
                </td>
                <td class="text-wrap font-weight-bold" :class="{'text-muted': row.is_hidden}">
                  {{ row.title }}
                </td>
                <td class="text-center">
                  <b-btn tag="a" variant="link" @click="editItem(row)" class="mr-2 px-3 py-2">
                    <b-icon-pencil-fill />
                  </b-btn>
                  <template v-if="!row.is_hidden">
                    <b-btn tag="a" variant="link" class="mr-2 px-3 py-2" v-b-tooltip="'Закрыть для записи'" @click="hide(row)">
                      <b-icon-eye-slash-fill />
                    </b-btn>
                    <b-btn variant="link" class="mr-2 px-3 py-2 opacity-1 text-white cursor-none">
                      <b-icon-eye-slash-fill />
                    </b-btn>
                  </template>
                  <template v-else>
                    <b-btn tag="a" variant="link" class="mr-2 px-3 py-2" v-b-tooltip="'Опубликовать'" @click="publish(row)" :disabled="row.is_published">
                      <b-icon-arrow-bar-up />
                    </b-btn>
                    <b-btn tag="a" variant="link" @click="unpublish(row)" class="mr-2 px-3 py-2">
                      <b-icon-arrow-counterclockwise />
                    </b-btn>
                  </template>
                </td>
              </template>
            </base-table>
          </div>
        </template>
        <template v-else>
          <b-skeleton-table
              class="bg-white"
              :rows="20"
              :columns="3"
              :table-props="{ bordered: true, striped: true }"
          />
        </template>

        <div class="d-flex justify-content-end" v-if="itemsPagination">
          <base-pagination
              :page-count="itemsPagination.last_page"
              :value="itemsPagination.current_page"
              @step="fetchData"
              class-footer="text-white"
          />
        </div>
    </div>
    <item-forms-modal :item="selectedItem" @on-update="fetchData" />
  </div>
</template>
<script>
import ItemFormsModal from './PublicationRelease/ItemFormsModal';
import PublicationReleaseResource from "../../../resources/publication-release";

export default {
  name: "PublicationReleaseList",
  components: {
    ItemFormsModal
  },
  data() {
    return {
      items: [],
      selectedItem: {},
      itemsPagination: null
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData(page, perPage) {
      this.fetch(PublicationReleaseResource, {}, page, perPage)
    },
    async remove(item) {
      await this.deleteItem(PublicationReleaseResource, item.id);
      await this.fetchData();
    },
    async hide(item) {
      item.is_hidden = true;
      await PublicationReleaseResource.save(item);
      await this.fetchData();
    },
    async publish(item) {
      await PublicationReleaseResource.publish(item.id);
      await this.fetchData();
    },
    async unpublish(item) {
      await PublicationReleaseResource.unpublish(item.id);
      await this.fetchData();
    },
    editItem(item) {
      this.selectedItem = item;
      this.$bvModal.show('itemFormsModal');
    }
  },
};
</script>
<style>
  .cursor-none {
    cursor: default !important;
  }
</style>
