<template>
  <div>
    <base-header type="default" class="pb-0 pb-md-4 mt--5 mt-md-0" />
    <div class="container-fluid mt-5 col-xl-6">
        <div class="card-header border-0 bg-transparent">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0 text-white">Профили</h3>
            </div>
          </div>
        </div>

        <div class="card table-responsive table-striped">
          <base-table
            class="table align-items-center table-flush"
            tbody-classes="list"
            :data="items"
            :thead-classes="'thead-light'"
          >
            <template slot="columns">
              <th>#</th>
              <th>Название</th>
              <th></th>
            </template>

            <template slot-scope="{ row }">
              <td class="py-0">{{ row.id }}</td>
              <td class="py-0">
                <a
                    class="btn text-xs mr-3 text-white border-0 px-1"
                    :class="`bg-${row.color}`"
                    :key="row.id"
                    variant="info"
                />
                {{ row.fdv }}
              </td>
              <td class="py-0">
                <b-btn tag="a" variant="link" @click="editItem(row)" class="mr-2 p-2 text-primary">
                  <b-icon-pencil-fill />
                </b-btn>
<!--                <b-btn tag="a" variant="outline-danger" @click="remove(row)" disabled>-->
<!--                  <i class="fas fa-trash" />-->
<!--                </b-btn>-->
              </td>
            </template>
          </base-table>
        </div>

<!--        <div class="card-footer d-flex justify-content-end">-->
<!--          <base-pagination></base-pagination>-->
<!--          <base-pagination total="1"></base-pagination>-->
<!--        </div>-->
    </div>
    <profile-forms-modal :item="selectedItem" @on-update="fetchData" />
  </div>
</template>
<script>
import ProfileFormsModal from "./Profile/ProfileFormsModal";

export default {
  name: "ProfileList",
  components: {
    ProfileFormsModal
  },
  data() {
    return {
      items: [],
      selectedItem: {},
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.items = await this.$store.getters.getDict('dict_profiles');
    },
    async remove(item) {
      await this.fetchData();
    },
    editItem(item) {
      this.selectedItem = item;
      this.$bvModal.show('profileFormsModal');
    }
  },
};
</script>
<style>

</style>
